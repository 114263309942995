import React from "react";
import "./Options.css";

import { Link } from "react-router-dom";

import studyIcon from "../images/icons/options/study.png";
import storeIcon from "../images/icons/options/store.png";
import moreIcon from "../images/icons/options/more.png";

import notificationIcon from "../images/icons/options/notification.png";
import assignmentIcon from "../images/icons/options/assignment.png";
import idcardIcon from "../images/icons/options/id-card.png";
import whatsappIcon from "../images/icons/options/whatsapp.png";
import datesheetIcon from "../images/icons/options/datesheet.png";
import joinIcon from "../images/icons/options/join.png";
import universityIcon from "../images/icons/options/university.png";
import pdfIcon from "../images/icons/options/pdf.png";
import handwrittenIcon from "../images/icons/options/handwritten.png";

export default function Options() {
  return (
    <div className="hide-extra-data">
      <div className="row mx-2">
        <a
          class="col-4 btn"
          onClick={() => {
            openNotification();
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={notificationIcon}
              alt="push-notifications"
            />
            <br />
            <b>Latest Notices</b>
          </div>
        </a>

        <Link class="col-4 btn" to="/study">
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={studyIcon}
              alt="push-notifications"
            />
            <br />
            <b>Study</b>
          </div>
        </Link>

        <Link
          class="col-4 btn"
          onClick={() => {
            openStoreContent();
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={storeIcon}
              alt="push-notifications"
            />
            <br />
            <b>Stores</b>
          </div>
        </Link>
      </div>

      <div className="row mx-2">
        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent("https://webservices.ignou.ac.in/assignments");
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={assignmentIcon}
              alt="push-notifications"
            />
            <br />
            <b>Assignment</b>
          </div>
        </a>

        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent("https://ignouadmission.samarth.edu.in/");
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={idcardIcon}
              alt="push-notifications"
            />
            <br />
            <b>ID Card</b>
          </div>
        </a>

        <a
          class="col-4 btn"
          onClick={() => {
            openWhatsApp();
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={whatsappIcon}
              alt="push-notifications"
            />
            <br />
            <b>WhatsApp</b>
          </div>
        </a>
      </div>

      <div className="row mx-2">
        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent(
              "http://ignou.ac.in/ignou/aboutignou/division/sed/datesheet"
            );
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={datesheetIcon}
              alt="push-notifications"
            />
            <br />
            <b>DateSheet</b>
          </div>
        </a>

        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent("https://ignouadmission.samarth.edu.in/");
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={joinIcon}
              alt="push-notifications"
            />
            <br />
            <b>Join IGNOU</b>
          </div>
        </a>

        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent(
              "http://ignou.ac.in/ignou/aboutignou/regional/website"
            );
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={universityIcon}
              alt="push-notifications"
            />
            <br />
            <b>Regional Center</b>
          </div>
        </a>
      </div>

      <div className="row mx-2">
        <Link class="col-4 btn" to="/more">
          <div>
            <img
              className=""
              width="40"
              height="40"
              src={moreIcon}
              alt="push-notifications"
            />
            <br />
            <b>More Options</b>
          </div>
        </Link>
      </div>
    </div>
  );
}

function showWebContent(url) {
  try {
    if (url != null) {
      window.Android.showWebContent(url);
    }
  } catch (e) {}
}

function openNotification() {
  try {
    window.Android.openNotification();
  } catch (e) {}
}

function openWhatsApp() {
  try {
    window.Android.openWhatsApp();
  } catch (e) {}
}

function directIntent(url) {
  try {
    window.Android.directIntent("1242155", url);
  } catch (e) {}
}

function openStoreContent() {
  try {
    window.Android.showStoreContent(0);
  } catch (e) {}
}

function openVideoActivity(url) {
  try {
    window.Android.openVideoActivity(url);
  } catch (e) {}
}
