import React from "react";
import "./BottomBar.css";
import { Link } from "react-router-dom";

import studyIcon from "../images/icons/options/study.png";
import storeIcon from "../images/icons/options/store.png";
import moreIcon from "../images/icons/options/more.png";
import homeIcon from "../images/icons/options/home.png";

export default function BottomBar(props) {
  return (
    <footer>
      <div className="row bottom-menu mx-1">
        <hr />
        {props.selectHome ? (
          <div class="col-3 ">
            <Link to="/" className="text-dark text-decoration-none align-top">
              <img width="25" height="25" src={homeIcon} alt="home--v1" />
              <br />
              <strong>Home</strong>
            </Link>
          </div>
        ) : (
          <div class="col-3">
            <Link to="/" className="text-dark text-decoration-none ">
              <img width="25" height="25" src={homeIcon} alt="home--v1" />
              <br />
              <strong>Home</strong>
            </Link>
          </div>
        )}

        {props.selectStudy ? (
          <div class="col-3">
            <Link
              to="/study"
              className="text-dark text-decoration-none align-top"
            >
              <img width="25" height="25" src={studyIcon} alt="saving-book" />
              <br />
              <strong>Study</strong>
            </Link>
          </div>
        ) : (
          <div class="col-3 text-decoration-none">
            <Link to="/study" className="text-dark text-decoration-none">
              <img width="25" height="25" src={studyIcon} alt="saving-book" />
              <br />
              <strong>Study</strong>
            </Link>
          </div>
        )}

        {props.selectStore ? (
          <div class="col-3">
            <Link
              onClick={() => {
                showStoreContent(0);
              }}
              className="text-dark text-decoration-none"
            >
              <img width="25" height="25" src={storeIcon} alt="home--v1" />
              <br />
              <strong>Study</strong>
            </Link>
          </div>
        ) : (
          <div class="col-3">
            <Link
              onClick={() => {
                showStoreContent(0);
              }}
              className="text-dark text-decoration-none"
            >
              <img width="25" height="25" src={storeIcon} alt="home--v1" />
              <br />
              <strong>Store</strong>
            </Link>
          </div>
        )}

        {props.selectMore ? (
          <div class="col-3">
            <Link to="/more" className="text-dark text-decoration-none">
              <img width="25" height="25" src={moreIcon} alt="home--v1" />
              <br />
              <strong>More</strong>
            </Link>
          </div>
        ) : (
          <div class="col-3">
            <Link to="/more" className="text-dark text-decoration-none">
              <img width="25" height="25" src={moreIcon} alt="home--v1" />
              <br />
              <strong>More</strong>
            </Link>
          </div>
        )}
      </div>
    </footer>
  );
}

function showWebContent(url) {
  if (url != null) {
    window.Android.showWebContent(url);
  }
}

function showStoreContent(url) {
  window.Android.showStoreContent(url);
}
