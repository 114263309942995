import React from "react";

import Carousel from "react-bootstrap/Carousel";

import img2 from "../images/latest_rc_updates_notification.jpg";
import img1 from "../images/img1.jpg";
import img3 from "../images/img3.jpg";
import img4 from "../images/img4.jpg";

export default function Carousal() {
  return (
    <>
      <Carousel className="slide m-3">
        <Carousel.Item interval={3000}>
          <a
            onClick={() => {
              openWhatsAppCustom(
                "9317126842",
                "I Want to Buy Handwritten, in Weekly Monday Sale Offer @ ~270rs~ 220rs each"
              );
            }}
          >
            <img src={img4} className="d-block w-100 border rounded-4" />
          </a>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <a
            onClick={() => {
              openNotification();
            }}
          >
            <img src={img2} className="d-block w-100 border rounded-4" />
          </a>
        </Carousel.Item>

        {/* <Carousel.Item interval={3000}>
          <a
            onClick={() => {
              openWhatsAppCustom(
                "9317126842",
                "I came here from IGNOUPLUS WebApp, Pls provide me Handwritten Assignments."
              );
            }}
          >
            <img src={img1} className="d-block w-100 border rounded-4" />
          </a>
        </Carousel.Item> */}

        <Carousel.Item interval={3000}>
          <img src={img3} className="d-block w-100 border rounded-4" />
        </Carousel.Item>
      </Carousel>
    </>
  );
}

function openWhatsAppCustom(number, message) {
  try {
    window.Android.openWhatsAppCustom(number, message);
  } catch (e) {}
}

function openNotification() {
  try {
    window.Android.openNotification();
  } catch (e) {}
}
