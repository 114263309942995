import React from "react";
import "./Options.css";

import { Link } from "react-router-dom";

import assignmentIcon from "../images/icons/options/assignment.png";
import paperIcon from "../images/icons/options/paper.png";
import projectIcon from "../images/icons/options/project.png";
import testIcon from "../images/icons/options/test.png";
import contractIcon from "../images/icons/options/contract.png";
import booksIcon from "../images/icons/options/books.png";

export default function Options() {
  return (
    <div className="hide-extra-data">
      <div className="row mx-2">
        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent("https://webservices.ignou.ac.in/assignments/");
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={assignmentIcon}
              alt="push-notifications"
            />
            <br />
            <b>Assignments</b>
          </div>
        </a>

        <Link
          class="col-4 btn"
          onClick={() => {
            openStoreContent();
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={paperIcon}
              alt="push-notifications"
            />
            <br />
            <b>Solved Assignments</b>
          </div>
        </Link>

        <Link class="col-4 btn" to="/store">
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={projectIcon}
              alt="push-notifications"
            />
            <br />
            <b>Projects</b>
          </div>
        </Link>
      </div>

      <div className="row mx-2">
        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent("https://webservices.ignou.ac.in/Pre-Question/");
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={testIcon}
              alt="push-notifications"
            />
            <br />
            <b>Question Papers</b>
          </div>
        </a>

        <Link
          class="col-4 btn"
          onClick={() => {
            openStoreContent();
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={contractIcon}
              alt="push-notifications"
            />
            <br />
            <b>Solved Papers</b>
          </div>
        </Link>

        <a
          class="col-4 btn"
          onClick={() => {
            showWebContent("https://egyankosh.ac.in/");
          }}
        >
          <div>
            <img
              className=""
              width="42"
              height="42"
              src={booksIcon}
              alt="push-notifications"
            />
            <br />
            <b>e-Gyan Kosh</b>
          </div>
        </a>
      </div>
    </div>
  );
}

function showWebContent(url) {
  if (url != null) {
    window.Android.showWebContent(url);
  }
}

function openNotification() {
  window.Android.openNotification();
}

function openWhatsApp() {
  window.Android.openWhatsApp();
}

function openStoreContent() {
  try {
    window.Android.showStoreContent(0);
  } catch (e) {}
}
