import React from "react";
import Carousal from "../page-home/Carousal";
import Options from "../page-home/Options";
import BottomBar from "../component/BottomBar";

export default function Home() {
  return (
    <div>
      <Carousal />

      <Options />

      <BottomBar selectHome="true" />
    </div>
  );
}
